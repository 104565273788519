import { Card, Flex, Typography, Button, theme } from "antd";
import { SendOutlined, BulbFilled } from "@ant-design/icons";
import React, { useState } from "react";

const { Text } = Typography;
const { useToken } = theme;

const SuggestionCard = ({ description, onClick }) => {
  const [hovered, setHovered] = useState(false);
  const { token } = useToken();

  return (
    <Card
      style={{
        borderRadius: "8px",
        padding: "12px",
        border: `1px solid`,
        borderColor: "#E1E4E6",
        backgroundColor: hovered ? "#FF6C42" : "white",
        transition: "border 0.3s ease",
        cursor: "pointer",
      }}
      bodyStyle={{ padding: 0 }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
    >
      <Flex
        align="center"
        justify="center" // This centers the content horizontally
        style={{
          width: "100%",
          paddingInline: "8px",
          whiteSpace: "nowrap", // This allows the text to wrap if it's too long
        }}
      >
        <Text
          style={{
            color: hovered ? "white" : "black",
            fontFamily: "Inter-Tight",
            fontWeight: 500,
            fontSize: "14px",
            textAlign: "center", // Ensures the text is centered if it's multiline
            width: "100%", // Ensures the text spans the full width of the Flex container
            overflow: "hidden", // Hides the text that overflows
            textOverflow: "ellipsis", // Adds ellipsis when the text is cut off
            whiteSpace: "nowrap", // Prevents the text from wrapping to the next line
          }}
        >
          {description}
        </Text>
      </Flex>
    </Card>
  );
};

export default SuggestionCard;
