import React from "react";
import { Flex, Typography, theme } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

const { Text } = Typography;
const { useToken } = theme;

function StatText({
  value,
  description,
  isIncrease = true,
  backgroundColor,
  color,
}) {
  const { token } = useToken();

  const defaultColor = isIncrease ? token.colorIncrease : token.colorError;
  const finalColor = color || token.colorIncreaseText;
  const finalBgColor = backgroundColor || `#E8F3DB`;

  return (
    <Flex justify="center" align="center">
      <Flex
        justify="center"
        align="center"
        style={{
          backgroundColor: finalBgColor,
          borderRadius: "8px",
          padding: "4px 8px",
          marginRight: "8px",
          width: '26px',
          height: '26px',
        }}
      >
        {isIncrease ? (
          <ArrowUpOutlined style={{ color: defaultColor, rotate: '45deg', width: '12px' }} />
        ) : (
          <ArrowDownOutlined style={{ color: finalColor, rotate: '-45deg', width: '12px' }} />
        )}
      </Flex>
      <Text style={{ color: finalColor, marginRight: "8px", fontSize: "14px", fontFamily: "Gilroy-Medium", fontWeight: 400 }}>
        {isIncrease ? "+" : "-"}
        {value + "%"}
      </Text>
      <Text type="secondary" style={{ fontSize: "12px", fontWeight: 400, fontFamily: "Gilroy-Regular" }}>
        {description}
      </Text>
    </Flex>
  );
}

export default StatText;
