import React, { useState } from "react";
import { Flex, Layout, Typography, theme } from "antd";
import {
  OverallProgressChartCard,
  HoursActivityChartCard,
  RecentResultCard,
  ProgressCard,
  ScoreStatsCards,
  ImprovementAreasCard,
} from "../../components";
import {
  TrophyOutlined,
  RiseOutlined,
  CheckCircleOutlined,
  StarOutlined,
  WarningOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  BulbOutlined,
} from "@ant-design/icons";

const { Text } = Typography;
const { Content, Sider } = Layout;
const { useToken } = theme;

const Reports = () => {
  const { token } = useToken();
  const [selectedTimeframeAreaChart, setSelectedTimeframeAreaChart] =
    useState("weekly");

  const timeframeOptions = [
    { value: "today", label: "Today" },
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
  ];

  const subjectOptions = [
    { value: "all", label: "Subjects" },
    { value: "math", label: "Mathematics" },
    { value: "physics", label: "Physics" },
    { value: "chemistry", label: "Chemistry" },
  ];

  const recentResults = [
    { subject: "Chemistry", score: 85.7, date: "10 Aug, 2024" },
    { subject: "Mathematics", score: 48.3, date: "09 Aug, 2024" },
    { subject: "Physics", score: 45.9, date: "08 Aug, 2024" },
    { subject: "Biology", score: 88.5, date: "07 Aug, 2024" },
    { subject: "Hindi", score: 95.0, date: "06 Aug, 2024" },
    { subject: "Social Studies", score: 91.2, date: "05 Aug, 2024" },
  ];

  return (
    <Flex
      vertical
      style={{
        padding: "16px",
      }}
    >
      <Flex style={{ marginBottom: 16 }}>
        <Text style={{ fontSize: "16px", fontFamily: "Gilroy-Semibold", fontWeight: 400 }}>
          Reports
        </Text>
      </Flex>
      <Layout
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        <Content>
          <Flex vertical style={{ width: "100%" }}>
            <Flex style={{ width: "100%", marginBottom: 16 }}>
              <OverallProgressChartCard
                timeframeOptions={timeframeOptions}
                subjectOptions={subjectOptions}
                selectedTimeframeAreaChart={selectedTimeframeAreaChart}
                setSelectedTimeframeAreaChart={setSelectedTimeframeAreaChart}
              />
            </Flex>
            <Flex style={{ width: "100%" }}>
              <HoursActivityChartCard
                timeframeOptions={timeframeOptions}
                subjectOptions={subjectOptions}
              />
              <RecentResultCard recentResults={recentResults} />
            </Flex>
          </Flex>
        </Content>
        <Sider
          style={{
            backgroundColor: token.colorBgLayout,
            marginLeft: "16px",
          }}
          width={"30%"}
        >
          <ProgressCard subjectOptions={subjectOptions} />
          <ScoreStatsCards />
        </Sider>
      </Layout>
      {/* Areas of Strengths (50%) & Areas of Weaknesses Cards (50%) */}
      <Flex style={{ width: "100%" }}>
        <ImprovementAreasCard
          title="Areas of Strengths"
          backgroundColor={token.colorGreen}
          textColor={token.textColorGreen}
          items={[
            {
              icon: <TrophyOutlined />,
              text: "Consistent High Scores",
            },
            {
              icon: <RiseOutlined />,
              text: "Quick Problem Solving",
            },
            {
              icon: <CheckCircleOutlined />,
              text: "Regular Practice",
            },
            {
              icon: <StarOutlined />,
              text: "Excellent Time Management",
            },
          ]}
        />
        <ImprovementAreasCard
          title="Areas of Weaknesses"
          backgroundColor={token.lightOrange}
          textColor={token.darkerOrange}
          items={[
            {
              icon: <WarningOutlined />,
              text: "Complex Equations",
            },
            {
              icon: <ClockCircleOutlined />,
              text: "Speed in Calculations",
            },
            {
              icon: <ExclamationCircleOutlined />,
              text: "Geometry Concepts",
            },
            {
              icon: <BulbOutlined />,
              text: "Theory Application",
            },
          ]}
        />
      </Flex>
    </Flex>
  );
};

export default Reports;
