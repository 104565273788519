import React from "react";
import { Typography } from "antd";

const { Text } = Typography;

function TextBadge({
  text,
  backgroundColor,
  textColor,
  isBorder = true,
  borderColor,
  textSize
}) {
  return (
    <div
      style={{
        border: isBorder ? `1px solid ${'#FFE1D9' || textColor}` : "none",
        borderRadius: "28px",
        backgroundColor,
        padding: "4px 16px",
        gap: "8px",
      }}
    >
      <Text style={{ color: textColor,
        fontSize: textSize || "10px",
        fontFamily: "Gilroy-Semibold",
        fontWeight: 400,
       }}
       
       bodyStyle={{padding: 0}}>{text}</Text>
    </div>
  );
}

export default TextBadge;
