import { Row, Col, Typography, Flex, Progress, theme } from "antd";
import { PlayCircleOutlined } from "@ant-design/icons";
import { QuizInfoCard, QuizSettingsIcon } from "../../components";
import { ASSETS } from "../../constants";

const { useToken } = theme;
const { Title, Text } = Typography;

function QuizInfoCardsSection({
  difficulty,
  timeLeft,
  questionsAnswered,
  totalQuestions,
  onPauseResume,
  onRestart,
  onStop,
  isPaused,
}) {
  const { token } = useToken();
  const progressPercentage = Math.round(
    (questionsAnswered / totalQuestions) * 100
  );

  return (
    <Flex
      vertical
      style={{
        backgroundColor: token.colorAccent,
        padding: "16px",
        borderRadius: "16px",
      }}
    >
      <Flex vertical>
        <Text
          style={{
            color: token.logoBlue,
            fontFamily: "Gilroy-SemiBold",
            fontSize: "20px",
            marginTop: "0px",
            marginBottom: "0px",
            marginLeft: "8px",
          }}
        >
          Get Ready to Challenge Yourself
        </Text>
        <Text
          style={{
            color: token.logoBlue,
            fontFamily: "Gilroy-Regular",
            fontSize: "14px",
            marginLeft: "8px",
            marginTop: "4px",
            marginBottom: "16px",
          }}
        >
          Dive into engaging questions to test your knowledge and boost your
          learning!
        </Text>
      </Flex>

      <Row gutter={[16, 16]}>
        <Col
          xs={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
        >
          <QuizInfoCard>
            <Flex vertical style={{ width: "100%" }}>
              <Flex justify="space-between">
                <Text
                  strong
                  style={{
                    color: token.logoBlue,
                    fontFamily: "Gilroy-SemiBold",
                    fontSize: "16px",
                  }}
                >
                  Time Elapsed
                </Text>
                <Text
                  style={{
                    margin: 0,
                    color: token.logoBlue,
                    fontFamily: "Gilroy-Bold",
                    fontSize: "24px",
                  }}
                >
                  {timeLeft}
                </Text>
              </Flex>
              <Flex justify="center">
                <QuizSettingsIcon
                  icon={
                    isPaused ? (
                      <img src={ASSETS.playQuizIcon} alt="play" />
                    ) : (
                      <img src={ASSETS.pauseQuizIcon} alt="pause" />
                    )
                  }
                  tooltipTitle={isPaused ? "Resume Quiz" : "Pause Quiz"}
                  onClick={onPauseResume}
                  border={token.border}
                />
                <QuizSettingsIcon
                  icon={<img src={ASSETS.restartQuizIcon} alt="restart" />}
                  tooltipTitle={"Restart Quiz"}
                  onClick={onRestart}
                  border={token.border}
                />
                <QuizSettingsIcon
                  icon={<img src={ASSETS.cancelQuizIcon} alt="stop" />}
                  tooltipTitle={"Stop Quiz"}
                  onClick={onStop}
                  border={token.border}
                />
              </Flex>
            </Flex>
          </QuizInfoCard>
        </Col>
        <Col
          xs={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
        >
          <QuizInfoCard>
            <Flex vertical style={{ width: "100%" }}>
              <Flex justify="space-between" align="center">
                <Text
                  strong
                  style={{
                    color: token.logoBlue,
                    fontFamily: "Gilroy-SemiBold",
                    fontSize: "16px",
                  }}
                >
                  Progress
                </Text>
                <Text
                  strong
                  style={{
                    color: token.logoBlue,
                    fontFamily: "Gilroy-Medium",
                    fontSize: "12px",
                  }}
                >
                  Difficulty : {difficulty}
                </Text>
              </Flex>
              <Flex vertical align="flex-end" style={{ marginTop: "10px" }}>
                <Text
                  style={{
                    color: token.logoBlue,
                    fontFamily: "Gilroy-SemiBold",
                    fontSize: "16px",
                  }}
                >{`${questionsAnswered}/${totalQuestions}`}</Text>
                <Progress
                  percent={progressPercentage}
                  showInfo={false}
                  size="small"
                  strokeColor={token.colorPrimary}
                />
              </Flex>
            </Flex>
          </QuizInfoCard>
        </Col>
      </Row>
    </Flex>
  );
}

export default QuizInfoCardsSection;
