import React from "react";
import { Flex, Typography, Spin, theme } from "antd";
import { MessageOption } from "../../components";
import AIAvatarIcon from "./ai-avatar-icon";

const { Text } = Typography;
const { useToken } = theme;

function Message({
  content,
  type,
  index,
  onReferenceClicked,
  references = [],
  isLoading = false,
}) {
  const isUser = type === "user";
  const { token } = useToken();

  return (
    <Flex
      vertical
      align={isUser ? "flex-end" : "flex-start"}
      style={{
        margin: "8px 0",
      }}
    >
      <Flex>
        {/* AI avatar */}
        {!isUser && (
          <AIAvatarIcon
            backgroundColor={token.colorChatSystemMessage}
            color={token.logoBlue}
            width="8"
            height="8"
          />
        )}
        {/* Message content */}
        <Flex
          style={{
            maxWidth: !isUser ? "60%" : "100%",
            padding: "8px 12px",
            borderRadius: "16px",
            background: isUser
              ? token.colorPrimary
              : token.colorChatSystemMessage,
            textAlign: isUser ? "right" : "left",
            minWidth: "auto",
            minHeight: "36px",
            alignItems: "center",
            borderBottomRightRadius: isUser ? "4px" : "16px",
            borderBottomLeftRadius: isUser ? "16px" : "4px",
          }}
        >
          {!isUser && isLoading ? (
            <Spin color={token.logoBlue} />
          ) : (
            <Flex vertical>
              <Text
                style={{
                  color: isUser ? "#fff" : "#000",
                  fontFamily: isUser ? "Inter-Tight" : "Gilroy-Regular",
                  fontWeight: isUser ? 400 : 300,
                }}
              >
                {content}
              </Text>
              {/* Reference are for the system response */}
              <div style={{ display: "flex" }}>
                {!isUser &&
                  references?.map((ref) => (
                    <div
                      key={ref.id}
                      style={{
                        height: "10%",
                        width: "fit-content",
                        margin: "3px",
                        padding: "1px 6px",
                        borderRadius: "16px",
                        background: "#d3dade",
                        color: token.colorPrimary,
                        cursor: "pointer",

                        display: "flex",
                        justifyContent: "center",
                      }}
                      onClick={() => onReferenceClicked(ref)}
                    >
                      <Text style={{ fontSize: "10px" }}>{ref.id}</Text>
                    </div>
                  ))}
              </div>
            </Flex>
          )}
        </Flex>
      </Flex>
      {/* Message options */}
      <Flex style={{ marginLeft: "40px" }}>
        {!isUser && !isLoading && (
          <MessageOption content={content} index={index} />
        )}
      </Flex>
    </Flex>
  );
}

export default Message;
