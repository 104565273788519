import {
  REGISTER_URL,
  LOGIN_URL,
  REQUEST_OTP_URL,
  VERIFY_OTP_URL,
  ONBOARDING_URL,
  PROFILE_URL,
  ACADEMIC_SETTINGS_URL,
  CHANGE_PASSWORD_URL,
  DELETE_ACCOUNT_URL,
} from "./api-urls";

export const registerUser = async (userData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  };

  try {
    const response = await fetch(REGISTER_URL, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const loginUser = async (credentials) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  };

  try {
    const response = await fetch(LOGIN_URL, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const requestOTP = async (otpData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(otpData),
  };

  try {
    const response = await fetch(REQUEST_OTP_URL, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const verifyOTP = async (otpData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(otpData),
  };

  try {
    const response = await fetch(VERIFY_OTP_URL, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const updateOnboarding = async (onboardingData, token) => {
  const requestOptions = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify(onboardingData),
  };

  try {
    const response = await fetch(ONBOARDING_URL, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getProfile = async (token) => {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  try {
    const response = await fetch(PROFILE_URL, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const updateProfile = async (profileData, token) => {
  // Convert the data to FormData
  const formData = new FormData();
  Object.keys(profileData).forEach((key) => {
    if (profileData[key] !== null) {
      formData.append(key, profileData[key]);
    }
  });

  const requestOptions = {
    method: "PATCH",
    headers: {
      Authorization: `Token ${token}`,
    },
    body: formData,
  };

  try {
    const response = await fetch(PROFILE_URL, requestOptions);
    const data = await response.json();
    // Update local storage
    const userData = JSON.parse(localStorage.getItem("userData"));
    userData.first_name = profileData.first_name;
    userData.last_name = profileData.last_name;
    userData.phone = profileData.phone;
    localStorage.setItem("userData", JSON.stringify(userData));
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getAcademicSettings = async (token) => {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  try {
    const response = await fetch(ACADEMIC_SETTINGS_URL, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const updateAcademicSettings = async (academicData, token) => {
  const requestOptions = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify(academicData),
  };

  try {
    const response = await fetch(ACADEMIC_SETTINGS_URL, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const changePassword = async (passwordData, token) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify(passwordData),
  };

  try {
    const response = await fetch(CHANGE_PASSWORD_URL, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const deleteAccount = async (token) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  try {
    const response = await fetch(DELETE_ACCOUNT_URL, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};
