import React, { useState, useEffect } from "react";
import { Flex, theme, Row, Col, Typography } from "antd";
import { BookCard } from "../../dashboard";
import { getBooksList } from "../../../services";
import { useNavigate } from "react-router-dom";

const { useToken } = theme;
const { Text } = Typography;

function BooksList() {
  const { token } = useToken();
  const [books, setBooks] = useState([]);
  const navigate = useNavigate();
  const [visibleCount, setVisibleCount] = useState(0);

  useEffect(() => {
    const updateVisibleCount = () => {
      const newCount = window.innerWidth < 1600 ? 2 : 3;
      setVisibleCount(newCount);
    };

    updateVisibleCount();

    window.addEventListener("resize", updateVisibleCount);

    return () => {
      window.removeEventListener("resize", updateVisibleCount);
    };
  }, []);

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const booksData = await getBooksList();
        const visibleBooks = booksData.ebooks.slice(0, visibleCount);
        setBooks(visibleBooks);
      } catch (error) {
        console.error("Error fetching books:", error);
      }
    };

    fetchBooks();
  }, [visibleCount]);

  return (
    <Flex vertical>
      <Flex
        justify="space-between"
        align="center"
        style={{ marginBottom: "16px" }}
      >
        <Text style={{ fontSize: "16px", fontFamily: "Gilroy-Semibold", fontWeight: 400 }}>
          My Books
        </Text>
        <Text
          style={{
            textDecoration: "underline",
            color: token.textColorGreen,
            cursor: "pointer",
            fontFamily: "Gilroy-Medium",
            fontWeight: 400,
            fontSize: "14px",
          }}
        >
          View All
        </Text>
      </Flex>
      <Row gutter={[16, 16]}>
        {books.map((book) => (
          <Col key={book.id} xs={24} sm={24} md={12} lg={12} xxl={8}>
            <BookCard
              bookName={book.title}
              chaptersCount={book.chapters.length}
              grade={9}
              timeTaken={3.5}
              progress={25}
              onClick={() => navigate(`/book/${book.id}`)}
            />
          </Col>
        ))}
      </Row>
    </Flex>
  );
}

export default BooksList;
