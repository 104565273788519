import React, { useEffect, useState } from "react";
import {
  Layout,
  Typography,
  Flex,
  Form,
  Row,
  Col,
  DatePicker,
  Button,
  Input,
  message,
  Select,
  theme,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { AvatarWithText, AppTextfield, AppDropdown } from "../../components";
import dayjs from "dayjs";
import { getProfile, updateProfile } from "../../services";
import { getAuth } from "../../utils";
import styled from "styled-components";

const { Content } = Layout;
const { Text } = Typography;
const { useToken } = theme;

const CustomFormItem = styled(Form.Item)`
  .ant-form-item-label label {
    font-size: 14px !important;
    font-family: Gilroy-Medium !important;
    font-weight: 400 !important;
  }
`;

const CustomInput = styled(Input)`
  background-color: ${(props) => props.theme.token.colorBgLayout};
  border-radius: 10px;
  height: 45px;
  border: none;
  font-size: 14px;
  padding: 0px 16px;
  font-family: Gilroy-Light;
  font-weight: 400;

  input {
    font-size: 14px;
    font-family: Gilroy-Light;
    font-weight: 400;
  }
`;

const CustomDatePicker = styled(DatePicker)`
  background-color: ${(props) => props.theme.token.colorBgLayout};
  border-radius: 10px;
  height: 45px !important;
  border: none !important;
  font-size: 14px !important;
  padding: 0px 16px;
  font-family: Gilroy-Light;
  font-weight: 400;

  input {
    font-size: 14px !important;
    font-family: Gilroy-Light;
    font-weight: 400;
  }

  .ant-picker-suffix {
    display: none;
  }
`;

const CustomSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 10px;
    height: 45px !important;
    padding: 14px 16px;
    border: none !important;
    background-color: ${(props) => props.theme.token.colorBgLayout};
    font-size: 14px;
    font-weight: 400;
    font-family: Gilroy-Light;
  }

  .ant-select-selector .ant-select-selection-search-input {
    font-size: 14px;
    font-weight: 400;
    font-family: Gilroy-Light;
    border: none;
  }

  .ant-select-selector .ant-select-selection-placeholder {
    font-size: 14px;
    font-weight: 400;
    font-family: Gilroy-Light;
    border: none;
  }

  .ant-select-selector .ant-select-selection-item {
    font-size: 14px;
    font-weight: 400;
    font-family: Gilroy-Light;
    border: none;
  }

  .ant-select-selector .ant-select-selection-item-content {
    font-size: 14px;
    font-weight: 400;
    font-family: Gilroy-Light;
    border: none;
  }
`;

function Profile() {
  const { token } = useToken();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const { authToken } = getAuth();

  /**
   * Mapping object to convert gender values between frontend display format
   * and backend storage format
   */
  const genderMapping = {
    male: "M",
    female: "F",
    other: "O",
  };

  /**
   * Reverse mapping object to convert gender values from backend format
   * to frontend display format
   */
  const reverseGenderMapping = {
    M: "male",
    F: "female",
    O: "other",
  };

  /**
   * Fetches user profile data from the backend and populates the form
   * with the retrieved data
   */
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await getProfile(authToken);
        setProfileData(data);

        // Populate form with profile data
        form.setFieldsValue({
          firstName: data.first_name,
          lastName: data.last_name,
          phone: data.phone?.replace("+91", ""), // Remove country code for display
          gender: reverseGenderMapping[data.gender] || "male",
          dob: data.date_of_birth ? dayjs(data.date_of_birth) : undefined,
          address: data.permanent_address,
          cityState: data.city_state,
          country: data.country,
          postalCode: data.post_code,
        });
      } catch (error) {
        message.error("Failed to fetch profile data");
      }
    };

    fetchProfile();
  }, [authToken, form]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const profileData = {
        first_name: values.firstName,
        last_name: values.lastName,
        phone: values.phone ? `+91${values.phone}` : "", // Add country code for storage
        gender: genderMapping[values.gender] || null,
        date_of_birth: values.dob ? values.dob.format("YYYY-MM-DD") : null,
        permanent_address: values.address || null,
        city_state: values.cityState || null,
        country: values.country || null,
        post_code: values.postalCode || null,
      };

      await updateProfile(profileData, authToken);

      message.success("Profile updated successfully");
    } catch (error) {
      message.error("Failed to update profile");
    } finally {
      setLoading(false);
    }
  };

  /**
   * Computes the full name from profile data for display
   */
  const fullName = profileData
    ? `${profileData.first_name} ${profileData.last_name}`
    : "";

  /**
   * Options for gender selection dropdown
   */
  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Other" },
  ];

  return (
    <Layout style={{ padding: "16px", backgroundColor: "#fff", height: "100%" }}>
      <Content>
        <Text style={{ fontSize: "16px", fontFamily: "Gilroy-Semibold", fontWeight: 400, marginBottom: "24px" }}>
          Profile
        </Text>

        <Flex gap={32} style={{ marginTop: "24px" }}>
          {/* Left Column - Avatar Section */}
          <div style={{ flex: "0 0 200px" }}>
            <AvatarWithText
              text=""
              avatarSize={160}
              initials={
                fullName
                  ? fullName.split(" ")[0][0] + fullName.split(" ")[1][0]
                  : ""
              }
              isEditable={true}
              onEditClick={() => {
                console.log("Edit clicked");
              }}
            />
          </div>

          {/* Right Column - Profile Form */}
          <div style={{ flex: 1 }}>
            <Form
              form={form}
              layout="vertical"
              style={{ maxWidth: "100%" }}
              requiredMark={false}
              onFinish={onFinish}
            >
              {/* Personal Information Section */}
              <Row gutter={16}>
                <Col span={12}>
                  <CustomFormItem label="First Name" name="firstName">
                    <AppTextfield placeholder="Enter first name" />
                  </CustomFormItem>
                </Col>
                <Col span={12}>
                  <CustomFormItem label="Last Name" name="lastName">
                    <AppTextfield placeholder="Enter last name" />
                  </CustomFormItem>
                </Col>
              </Row>

              {/* Row 2 - Phone Number */}
              <CustomFormItem
                label="Phone Number"
                name="phone"
                className="custom-form-item"
              >
                <CustomInput
                  prefix={
                    <img
                      alt="United States"
                      src="http://purecatamphetamine.github.io/country-flag-icons/3x2/IN.svg"
                      style={{
                        width: "30px",
                        height: "auto",
                      }}
                    />
                  }
                  placeholder="123 123 1234"
                  className="custom-input"
                  style={{ backgroundColor: "#F5F7FA" }}
                />
              </CustomFormItem>

              {/* Personal Details Section */}
              <Row gutter={16}>
                <Col span={12}>
                  <CustomFormItem label="Gender" name="gender">
                    <CustomSelect
                      width={"100%"}
                      options={genderOptions}
                      dropdownStyle={{
                        borderRadius: "8px",
                        backgroundColor: token.colorWhite,
                      }}
                      placeholder="Select gender"
                      size={"large"}
                      suffixIcon={<DownOutlined style={{ color: token.colorTextSecondary, width: '16px', height: '16px' }} />}
                    />
                  </CustomFormItem>
                </Col>
                <Col span={12}>
                  <CustomFormItem label="Date of Birth" name="dob">
                    <CustomDatePicker
                      style={{ width: "100%", backgroundColor: "#F5F7FA" }}
                      size="large"
                      format="DD/MM/YYYY"
                    />
                  </CustomFormItem>
                </Col>
              </Row>

              {/* Address Section */}
              <Row gutter={16}>
                <Col span={12}>
                  <CustomFormItem
                    label="Permanent Address (Optional)"
                    name="address"
                  >
                    <AppTextfield placeholder="Enter address" />
                  </CustomFormItem>
                </Col>
                <Col span={12}>
                  <CustomFormItem label="City/State (Optional)" name="cityState">
                    <AppTextfield placeholder="Enter city and state" />
                  </CustomFormItem>
                </Col>
              </Row>

              {/* Additional Address Details */}
              <Row gutter={16}>
                <Col span={12}>
                  <CustomFormItem label="Country (Optional)" name="country">
                    <AppTextfield placeholder="Enter country" />
                  </CustomFormItem>
                </Col>
                <Col span={12}>
                  <CustomFormItem label="Postal Code (Optional)" name="postalCode">
                    <AppTextfield placeholder="Enter postal code" />
                  </CustomFormItem>
                </Col>
              </Row>

              {/* Form Submit Button */}
              <Form.Item style={{ width: "100%" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%", marginBottom: "12px", height: "49px", padding: "16px", borderRadius: "10px", fontSize: "14px", fontFamily: "Gilroy-Semibold", fontWeight: 400 }}
                  loading={loading}
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Flex>
      </Content>
    </Layout>
  );
}

export default Profile;
