import React from "react";
import { Flex, Typography, theme } from "antd";
import {
  AppDropdown,
  StatTextRow,
  OverallProgressAreaChart,
} from "../../components";

const { Text } = Typography;
const { useToken } = theme;

const OverallProgressChartCard = ({
  timeframeOptions,
  subjectOptions,
  selectedTimeframeAreaChart,
  setSelectedTimeframeAreaChart,
}) => {
  const { token } = useToken();

  return (
    <Flex
      vertical
      style={{
        flex: "0 0 100%",
        backgroundColor: token.colorWhite,
        borderRadius: 20,
        maxWidth: "100%",
        padding: 16,
        height: 400,
        gap: 10,
      }}
    >
      <div>
      <Flex justify="space-between" align="center" style={{marginBottom: '4px'}} bodyStyle={{ padding: 0 }}>
        <Text style={{ fontSize: "16px", fontWeight: 400, fontFamily: "Gilroy-Semibold" }}>
          Overall Progress
        </Text>
        <Flex gap={8}>
          <AppDropdown
            defaultValue="weekly"
            options={timeframeOptions}
            width={120}
            onChange={setSelectedTimeframeAreaChart}
            value={selectedTimeframeAreaChart}
          />
          <AppDropdown
            defaultValue="all"
            options={subjectOptions}
            width={140}
          />
        </Flex>
      </Flex>

      <Flex style={{ marginBottom: 10 }}>
        <StatTextRow
          value="12.5"
          description="Increase since last month"
          isIncrease={true}
        />
      </Flex>
      </div>

      <div
        style={{
          flex: 1,
          height: 300,
          backgroundColor: token.colorWhite,
          borderRadius: 8,
        }}
      >
        <OverallProgressAreaChart timeframe={selectedTimeframeAreaChart} />
      </div>
    </Flex>
  );
};

export default OverallProgressChartCard;
