import React from "react";
import { Select, theme } from "antd";
import { DownOutlined } from "@ant-design/icons";
import styled from "styled-components";

const CustomSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 28px;
    height: 28px;
    padding: 8px;
    width: 85px !important;
    border: 1px solid #6B7B82;
    background-color: #FFFFFF !important;
    color: #6B7B82;
    font-size: 10px;
    font-weight: 400;
    font-family: Gilroy-Medium;
  }
`;

const { useToken } = theme;

function AppDropdown({
  options,
  defaultValue,
  width = 120,
  onChange,
  value,
  borderRadius = "24px",
  size = "medium",
  backgroundColor,
}) {
  const { token } = useToken();

  return (
    <CustomSelect
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      options={options}
      dropdownStyle={{
        borderRadius: "8px",
        backgroundColor: backgroundColor ?? token.colorWhite,
      }}
      size={size}
      suffixIcon={<DownOutlined style={{ color: token.colorTextSecondary, width: '16px', height: '16px' }} />}
    />
  );
}

export default AppDropdown;
