import { CHAT_URL } from "./api-urls";

export const sendChatMessage = async (messageData, token) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify(messageData),
  };

  console.log("requestOptions", requestOptions);

  const response = await fetch(CHAT_URL, requestOptions);

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};
