import React from "react";
import { Flex, Button, Layout, Typography, theme } from "antd";
import { ASSETS } from "../../../constants";
import { ProgressCard, ScoreStatsCards } from "../../../components";

const { Sider } = Layout;
const { Title, Text } = Typography;
const { useToken } = theme;

function DashboardSider() {
  const { token } = useToken();

  const subjectOptions = [
    { value: "all", label: "Subjects" },
    { value: "math", label: "Mathematics" },
    { value: "physics", label: "Physics" },
    { value: "chemistry", label: "Chemistry" },
  ];

  const renderGoPremiumCard = () => {
    return (
      <Flex
        style={{
          width: "100%",
          backgroundColor: token.colorWhite,
          padding: "20px",
          borderRadius: "20px",
          position: "relative",
          overflow: "hidden",
          gap: '24px',
          marginBottom: '16px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <img
            src={ASSETS.dashboardPremiumImage}
            style={{ width: "80%" }}
            alt="Go Premium"
          />
        </div>
        <div style={{gap: '14px', display: 'flex', flexDirection: 'column'}}>
          <div style={{gap: '6px', display: 'flex', flexDirection: 'column'}}>
            <Text style={{fontFamily: 'Inter-Tight', fontSize: '16px', fontWeight: 500, color: token.colorBlack}}>Go Premium</Text>
            <Text style={{fontFamily: 'Inter-Tight', fontSize: '12px', fontWeight: 400, color: token.textColorGrey}}>
            Get more with Premium: access all content, study materials, and expert support to reach your goals!
            </Text>
          </div>
          <Button style={{
            backgroundColor: token.colorPrimary, 
            color: token.colorWhite, 
            fontFamily: 'Gilroy-Regular', 
            fontSize: '12px', 
            fontWeight: 400, 
            padding: '12px 16px', 
            borderRadius: '1000px', 
            gap: '10px',
            border: 'None',
            width: 'fit-content',}}>
            Get Access
          </Button>
        </div>
      </Flex>
    );
  };

  return (
    <Sider
      style={{
        backgroundColor: token.colorBgLayout,
        marginLeft: "16px",
      }}
      width={"30%"}
    >
      {renderGoPremiumCard()}
      <ProgressCard subjectOptions={subjectOptions} />
      <ScoreStatsCards />
    </Sider>
  );
}

export default DashboardSider;
