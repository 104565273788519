import React, { useState } from "react";
import { Flex, Input, Button, Space, Typography } from "antd";

const { TextArea } = Input;
const { Text } = Typography;

function ChatInput({ onSend, closeRecommendations }) {
  const [inputValue, setInputValue] = useState("");
  const maxLength = 500;

  const handleSend = () => {
    if (inputValue.trim()) {
      closeRecommendations();
      onSend(inputValue);
      setInputValue("");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <Flex
      direction="horizontal"
      style={{
        width: "100%",
        backgroundColor: "#F7F7F8",
        padding: "8px",
        alignItems: "center",
        borderRadius: "8px",
        transition: "border-color 0.3s ease",
      }}
    >
      {/* Left icon */}
      <img
        src="/images/chat_icon.svg"
        alt="Chat Icon"
        style={{ width: "16px", height: "16px", marginLeft: "8px" }}
      />

      {/* Textarea */}
      <TextArea
        placeholder="Ask anything"
        autoSize={{ minRows: 1, maxRows: 6 }}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyPress={handleKeyPress}
        style={{
          border: "none",
          resize: "none",
          boxShadow: "none",
          flex: 1,
          backgroundColor: "#F7F7F8",
          fontFamily: "Inter-Tight",
          fontWeight: 400,
        }}
        maxLength={maxLength}
      />

      {/* Character counter */}
      <Text
        style={{
          marginLeft: "8px",
          fontSize: "14px",
          fontFamily: "Inter-Tight",
          fontWeight: "400",
          color: "#8C8C8C",
        }}
      >
        {inputValue.length}/{maxLength}
      </Text>

      {/* Send button */}
      <Space
        style={{ display: "flex", alignItems: "center", marginLeft: "8px" }}
      >
        <Button
          type="primary"
          shape="circle"
          icon={
            <img
              src="/images/send_icon.svg"
              alt="Send"
              style={{ width: "16px", height: "16px" }}
            />
          }
          style={{
            backgroundColor: "transparent",
            border: "none",
            padding: 0,
            boxShadow: "none",
          }}
          onClick={handleSend}
        />
      </Space>
    </Flex>
  );
}

export default ChatInput;
