import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Empty, Flex, theme, Collapse } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { ContentBox, BookInformation } from "../book";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import styled from "styled-components";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

const { Content } = Layout;
const { useToken } = theme;

const BookDrawerCollapse = styled(Collapse)`
  background-color: ${({ theme }) => theme.token.colorPrimary} !important;
  border: none !important;
  margin: 0 !important;

  .ant-collapse-content-box {
    padding: 0px !important;
  }

  .ant-collapse-item {
    border-bottom: none !important;
  }

  .ant-collapse-header {
    padding: 12px !important;
    align-items: center !important;
  }

  .ant-collapse-content {
    background-color: ${({ theme }) => theme.token.colorWhite} !important;
  }
`;

function Book({ contentRef, collapsed, showChat }) {
  const { token } = useToken();
  const { bookId, chapterIds } = useParams();
  const navigate = useNavigate();
  const [books, setBooks] = useState(null);
  const [selectedBook, setSelectedBook] = useState(null);
  const [selectedChapters, setSelectedChapters] = useState([]);
  const screens = useBreakpoint();
  const isMobile = !screens.md;

  useEffect(() => {
    const storedBooks = JSON.parse(localStorage.getItem("booksList"));
    const currentPath = window.location.pathname;

    if (storedBooks) {
      setBooks(storedBooks);

      if (
        bookId &&
        (currentPath.startsWith("/book/") || currentPath.startsWith("/test/"))
      ) {
        const book = storedBooks.find(
          (book) => book.id === parseInt(bookId, 10)
        );
        if (book) {
          setSelectedBook(book);

          // Set chapters from URL if present, otherwise clear them
          if (chapterIds) {
            const selectedIds = chapterIds
              .split("&&")
              .map((id) => parseInt(id, 10));
            setSelectedChapters(selectedIds);
          } else {
            setSelectedChapters([]); // Clear chapters when no chapterIds in URL
          }
        }
      } else {
        setSelectedBook(null);
        setSelectedChapters([]); // Clear chapters when on base route
      }
    }
  }, [bookId, chapterIds, setSelectedChapters]);

  const handleBookSelect = (book) => {
    const currentPath = window.location.pathname;
    const baseRoute = currentPath.startsWith("/test") ? "test" : "book";

    if (book === null) {
      setSelectedBook(null);
      setSelectedChapters([]);
      navigate(`/${baseRoute}`);
    } else {
      setSelectedBook(book);
      // Keep existing selected chapters when selecting a book
      const chapterParam =
        selectedChapters.length > 0 ? `/${selectedChapters.join("&&")}` : "";
      navigate(`/${baseRoute}/${book.id}${chapterParam}`);
    }
  };

  if (!books) {
    return (
      <Flex justify="center" align="center" style={{ height: "100%" }}>
        <Empty description="Oops! Looks like we don't have books for you yet." />
      </Flex>
    );
  }

  const renderBookSelection = () => {
    if (isMobile) {
      return (
        <BookDrawerCollapse
          defaultActiveKey={["1"]}
          expandIconPosition="end"
          expandIcon={({ isActive }) =>
            isActive ? (
              <UpOutlined
                style={{
                  fontSize: "12px",
                  strokeWidth: 70,
                  color: token.colorBlack,
                }}
              />
            ) : (
              <DownOutlined
                style={{
                  fontSize: "12px",
                  strokeWidth: 70,
                  color: token.colorBlack,
                }}
              />
            )
          }
        >
          <Collapse.Panel
            header={
              <span
                style={{
                  fontWeight: "600",
                  fontSize: "14px",
                  color: token.colorWhite,
                }}
              >
                Select your Book or Chapter
              </span>
            }
            key="1"
          >
            <BookInformation
              books={books}
              selectedBook={selectedBook}
              onBookSelect={handleBookSelect}
              collapsed={false}
            />
          </Collapse.Panel>
        </BookDrawerCollapse>
      );
    }

    return (
      <BookInformation
        books={books}
        selectedBook={selectedBook}
        onBookSelect={handleBookSelect}
        collapsed={false}
      />
    );
  };

  return (
    <Layout style={{ backgroundColor: "white" }}>
      <Content
        style={{
          padding: "12px",
          width: "100%",
          height: "100%",
          backgroundColor: "FFFFFF",
        }}
      >
        <Row gutter={[0, 16]}>
          <Col xs={24} md={6} lg={6}>
            <div
              style={{
                backgroundColor: "#FFFFFF",
                borderRadius: "12px",
                height: "100%",
                border: "1px solid #F0F2F2",
              }}
            >
              {renderBookSelection()}
            </div>
          </Col>
          <Col xs={24} md={18} lg={18}>
            <ContentBox bookData={selectedBook} showChat={showChat} />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default Book;
