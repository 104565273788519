import React from "react";
import PropTypes from "prop-types";
import { DrawerMenu, SectionLabel } from "./styles";

// A component that renders a section of the drawer with a label and a menu.
// The website has three sections: Navigation, Main, and Settings.
const DrawerSection = ({
  label,
  items,
  selectedKeys,
  onItemClick,
  collapsed,
}) => {
  // Check if a menu item should be selected
  const getSelectedKeys = () => {
    const currentPath = selectedKeys[0];
    return items
      .filter(
        (item) => item.key !== "/logout" && currentPath?.includes(item.key)
      )
      .map((item) => item.key);
  };

  return (
    <div style={{ marginBottom: "16px" }}>
      <SectionLabel>{label}</SectionLabel>
      <DrawerMenu
        items={items.map((item) => ({
          ...item,
          label: React.cloneElement(item.label, { collapsed }),
        }))}
        selectedKeys={getSelectedKeys()}
        mode="inline"
        onClick={onItemClick}
        label={label}
      />
    </div>
  );
};

DrawerSection.propTypes = {
  label: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      icon: PropTypes.element,
      children: PropTypes.array,
    })
  ).isRequired,
  selectedKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  onItemClick: PropTypes.func.isRequired,
};

export default DrawerSection;
