import React, { useState } from "react";
import { Layout, theme } from "antd";
import {
  MessageList,
  ChatInput,
  ChatEmptyState,
  PDFModal,
} from "../../../components";
import { sendChatMessage } from "../../../services";
import { getAuth } from "../../../utils";
import { useSelectedChapters } from "../../../contexts";

const { Content, Footer } = Layout;
const { useToken } = theme;

function Chat({ bookData }) {
  const [messages, setMessages] = useState([]);
  const [showRecommendations, setShowRecommendations] = useState(false);
  const [followUpQuestions, setFollowUpQuestions] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const { selectedChapters } = useSelectedChapters();
  const { token } = useToken();

  const addMessage = (content, type, references = [], isLoading = false) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { content, type, references, isLoading },
    ]);
  };

  const handleSendMessage = async (message) => {
    // Add user message
    addMessage(message, "user");

    // Add a loading system message immediately
    const loadingMessageIndex = messages.length + 1;
    addMessage("", "system", [], true);

    try {
      console.log("Selected Chapters:", selectedChapters);
      const payload = {
        ebook_id: bookData.id,
        chapter_ids:
          selectedChapters.length > 0
            ? selectedChapters
            : [bookData.chapters[0].id], // Default to first chapter if none selected
        query: message,
      };

      const { authToken } = getAuth();
      const response = await sendChatMessage(payload, authToken);

      // Update the loading message with actual response

      console.log("Chat API Response:", response);

      setMessages((prevMessages) =>
        prevMessages.map((msg, index) =>
          index === loadingMessageIndex
            ? {
                content: response.response,
                type: "system",
                references: response.reference_chunks
                  ?.slice(0, 4)
                  .map((chunk, index) => ({
                    id: index + 1,
                    text: chunk.text,
                    metadata: chunk.metadata,
                  })),
                isLoading: false,
              }
            : msg
        )
      );

      setFollowUpQuestions(response.follow_up_questions);
      setShowRecommendations(true);
    } catch (error) {
      console.error("Chat API Error:", error);
      // Update the loading message with error
      setMessages((prevMessages) =>
        prevMessages.map((msg, index) =>
          index === loadingMessageIndex
            ? {
                content: "Sorry, there was an error processing your request.",
                type: "system",
                references: [],
                isLoading: false,
              }
            : msg
        )
      );
    }
  };

  const handleCloseRecommendations = () => {
    setShowRecommendations(false);
  };

  const showPDFModal = () => {
    setModalVisible(true);
  };

  return (
    <Layout
      style={{
        height: "100%",
        borderBottomLeftRadius: "16px",
        borderBottomRightRadius: "16px",
        backgroundColor: token.colorWhite,
      }}
    >
      <Content
        style={{
          padding: "12px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
        }}
      >
        {messages.length !== 0 ? (
          <MessageList
            messages={messages}
            showRecommendations={showRecommendations}
            onCloseRecommendations={handleCloseRecommendations}
            onSendRecommendation={handleSendMessage}
            onReferenceClicked={showPDFModal}
            followUpQuestions={followUpQuestions}
          />
        ) : (
          <ChatEmptyState onSend={handleSendMessage} />
        )}
      </Content>

      <PDFModal visible={modalVisible} onClose={() => setModalVisible(false)} />

      <Footer style={{ padding: "0 12px 12px", background: "transparent" }}>
        <ChatInput
          onSend={handleSendMessage}
          closeRecommendations={handleCloseRecommendations}
        />
      </Footer>
    </Layout>
  );
}

export default Chat;
