import React from "react";
import { Flex, Typography, Card, theme } from "antd";

const { Text } = Typography;
const { useToken } = theme;

const ImprovementAreasCard = ({ title, backgroundColor, textColor, items }) => {
  const { token } = useToken();

  return (
    <Flex
      vertical
      style={{
        flex: "0 0 49.2%",
        maxWidth: "49.2%",
        padding: "16px",
        backgroundColor: token.colorWhite,
        marginTop: "16px",
        marginRight: "16px",
        borderRadius: "20px",
      }}
    >
      <Text style={{ fontSize: "16px", marginBottom: "16px", fontWeight: 400, fontFamily: "Gilroy-Semibold" }}>
        {title}
      </Text>
      <Flex wrap="wrap" gap={12}>
        {items.map((item, index) => (
          <Card
            key={index}
            style={{
              backgroundColor: token.colorWhite,
              borderRadius: "16px",
              boxShadow: "0px 12px 34.8px 0px #0000000D",
              border: "none",
              flex: "0 0 calc(25% - 9px)",
            }}
            bodyStyle={{ padding: "16px" }}
          >
            <Flex align="start" vertical>
              <Flex
                justify="center"
                align="center"
                style={{
                  backgroundColor: backgroundColor,
                  borderRadius: "8px",
                  padding: '8px',
                  marginRight: "12px",
                }}
              >
                {React.cloneElement(item.icon, {
                  style: { color: token.colorWhite, fontSize: "20px", 
                    width: "20px",
                    height: "20px", },
                })}
              </Flex>
              <Text
                style={{ color: textColor, marginTop: "24px", fontWeight: 400, fontSize: "12px", fontFamily: "Gilroy-Semibold" }}
              >
                {item.text}
              </Text>
            </Flex>
          </Card>
        ))}
      </Flex>
    </Flex>
  );
};

export default ImprovementAreasCard;
