import React from "react";
import { Flex, Row, Col, Typography } from "antd";
import { ASSETS } from "../../constants";
import { SuggestionCard } from "../../components";

const { Text } = Typography;

function ChatEmptyState({ onSend }) {
  const handleSuggestionClick = (suggestion) => {
    onSend(suggestion);
  };

  return (
    <Flex
      vertical
      justify="center"
      align="center"
      style={{ width: "100%", height: "100vh" }}
    >
      <div style={{ textAlign: "center" }}>
        <img
          src={ASSETS.chatInitialBookIcon}
          alt="Logo"
          style={{
            width: "50px",
            padding: "10px",
            borderRadius: "12px",
            border: "4px solid #FFD3C6",
            marginBottom: "10px",
          }}
        />
        <div>
          <span
            style={{
              fontSize: "24px",
              fontFamily: "Inter-Tight",
              fontWeight: 500,
            }}
          >
            Curious about something?{" "}
            <span
              style={{
                color: "#FF6C42",
                fontFamily: "Inter-Tight",
                fontWeight: 500,
              }}
            >
              Fire away!
            </span>
          </span>
        </div>
      </div>

      <Text
        style={{
          marginTop: "40px",
          fontFamily: "Inter-Tight",
          fontWeight: 500,
          fontSize: "14px",
          textAlign: "center",
          color: "#6B7B82",
        }}
      >
        Suggestions
      </Text>

      {/* Suggestion cards row */}
      <Row
        gutter={[16, 16]}
        style={{ marginTop: "20px", justifyContent: "center" }} // Ensure horizontal centering of cards
      >
        <Col
          xs={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 8 }}
          xl={{ span: 8 }}
        >
          <SuggestionCard
            description="Summarize this book"
            onClick={() => handleSuggestionClick("Summarize this book")}
          />
        </Col>
        <Col
          xs={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 8 }}
          xl={{ span: 8 }}
        >
          <SuggestionCard
            description="Summarize important questions"
            onClick={() =>
              handleSuggestionClick("Summarize important questions")
            }
          />
        </Col>
        <Col
          xs={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 8 }}
          xl={{ span: 8 }}
        >
          <SuggestionCard
            description="Write conclusion for chapter 1"
            onClick={() =>
              handleSuggestionClick("Write conclusion for chapter 1")
            }
          />
        </Col>
      </Row>
    </Flex>
  );
}

export default ChatEmptyState;
