import React, { useEffect, useState } from "react";
import { Flex, Typography, theme, Spin, Empty } from "antd";
import { getAuth } from "../../utils";
import { SubjectIconRecentResults } from "../../assets";
import { ColoredDecimalNumber } from "../../components";
import { getRecentResults } from "../../services";
import { formatDate } from "../../utils";
import { quizTypes } from "../../constants";

const { Text } = Typography;
const { useToken } = theme;

const RecentResultCard = (props) => {
  const { token } = useToken();
  const [recentResults, setRecentResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getQuizTypeLabel = (backendValue) => {
    const quizType = quizTypes.find((type) => type.backend === backendValue);
    return quizType ? quizType.label : backendValue;
  };

  useEffect(() => {
    const fetchRecentResults = async () => {
      try {
        const { authToken } = getAuth();
        const results = await getRecentResults(authToken);
        console.log("Recent results:", results);
        // const results = props.recentResults;
        setRecentResults(results);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRecentResults();
  }, []);

  const RecentResultItem = ({ subject, score, completed_at, quiz_type, style }) => (
    <Flex align="center" style={{ padding: '8px 16px', ...style, }}>
      <Flex
        justify="center"
        align="center"
        style={{
          padding: 12,
          borderRadius: 12,
          backgroundColor: token.colorBgLayout,
        }}
      >
        <SubjectIconRecentResults color={token.colorPrimary} />
      </Flex>
      <Flex vertical style={{ marginLeft: 12 }}>
        <Flex align="center">
          <Text style={{ marginRight: 4, fontSize: '14px', fontFamily: 'Gilroy-Semibold', fontWeight: 400 }}>
            {subject}
          </Text>
          {/* <Text type="secondary">- {getQuizTypeLabel(quiz_type)}</Text> */}
        </Flex>
        <Text style={{fontSize: '12px', fontFamily: 'Inter-Tight', fontWeight: '500px', color: token.textColorGrey}}>
          {formatDate(completed_at)}
          {/* {date} */}
          </Text>
      </Flex>
      <div style={{ marginLeft: "auto" }}>
        <ColoredDecimalNumber
          number={score}
          color={score > 50 ? token.colorGreen : token.colorPrimary}
          size={20}
        />
      </div>
    </Flex>
  );

  const renderContent = () => {
    if (loading) {
      return (
        <Flex justify="center" align="center" style={{ padding: "16px", height: "100%" }}>
          <Spin size="large" />
        </Flex>
      );
    }

    if (error) {
      return (
        <Flex justify="center" align="center" style={{ padding: "16px", height: "100%" }}>
          <Empty
            description={
              <Text type="danger">Error loading recent results: {error}</Text>
            }
          />
        </Flex>
      );
    }

    if (!recentResults.length) {
      return (
        <Flex justify="center" align="center" style={{ padding: "16px", height: "100%" }}>
          <Empty description="No recent results available" />
        </Flex>
      );
    }

    const visibleResults = recentResults.slice(0, 4);

    return (
      <Flex
        vertical
        style={{
          height: '100%',
          overflowY: "auto",
          gap: '8px',
        }}
      >
        {visibleResults.map((result, index) => (
          <RecentResultItem
          key={index}
          {...result}
          style={{
            borderBottom:
              index === visibleResults.length - 1
                ? "none" // Remove border for the last item
                : `1px solid ${token.colorBgLayout}`,
          }}
        />
        ))}
      </Flex>
    );
  };

  return (
    <Flex
      vertical
      style={{
        flex: 1,
        backgroundColor: token.colorWhite,
        borderRadius: 20,
        marginLeft: 8,
        height: "343px",
      }}
    >
      <Text style={{ padding: "0px 16px", paddingTop: "16px", fontSize: "16px", fontWeight: "400px", fontFamily: "Gilroy-Semibold", marginBottom: 16 }}>
        Recent Results
      </Text>
      {renderContent()}
    </Flex>
  );
};

export default RecentResultCard;
