// This is the file where the menu items of the drawer are defined.
import { Flex, theme } from "antd";
import {
  DashboardDrawerIcon,
  ReportsDrawerIcon,
  AskYourBookDrawerIcon,
  TakeATestDrawerIcon,
  LangPracticeDrawerIcon,
  SettingsDrawerIcon,
  NotificationsDrawerIcon,
  LogoutDrawerIcon,
} from "../../assets";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

const { useToken } = theme;

// A component that renders the label of a menu item with a badge count if it exists
const MenuItemLabel = ({ label, badgeCount, isSelected, isCollapsed }) => {
  const { token } = useToken();

  return (
    <Flex
      justify="space-between"
      align="center"
      style={{
        flex: 1, // Ensure it takes up the available space
        marginLeft: "16px",
      }}
    >
      {!isCollapsed && (
        <span
          style={{
            whiteSpace: "nowrap", // Prevents text wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontFamily: isSelected ? "Gilroy-Semibold" : "Gilroy-Regular",
          }}
        >
          {label}
        </span>
      )}
      {badgeCount && (
        <div
          style={{
            backgroundColor: isSelected ? token.colorWhite : token.colorPrimary,
            color: isSelected ? token.logoBlue : token.colorWhite,
            borderRadius: "4px",
            fontSize: "10px",
            lineHeight: "24px",
            padding: "8px",
            minWidth: "20px", // Minimum width for single digits
            width: "22px",
            height: "22px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "0px 4px 4.3px 0px #0000000A",
            marginLeft: "auto", // Ensures it moves to the end of the flex container
          }}
        >
          {badgeCount}
        </div>
      )}
    </Flex>
  );
};


// MenuItemContent component to handle hover and selected states
const MenuItemContent = ({ label, Icon, badgeCount, itemKey, collapsed }) => {
  const { token } = useToken();
  const [isHovered, setIsHovered] = useState(false);
  const location = useLocation();

  // Check if current path includes the menu item's path (for nested routes)
  const isSelected =
    itemKey !== "/logout" && location.pathname.includes(itemKey);

    const getTextColor = () => {
      if (isSelected) return token.colorWhite;
      return token.logoBlue;
    };

  return (
    <Flex
      align="center"
      style={{
        color: getTextColor(),
        fontSize: "14px",
        fontWeight: 400,
        fontFamily: "Gilroy-Semibold",
        cursor: "pointer",
        transition: "background-color 0.1s, color 0.1s",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {Icon && <Icon className="menu-icon" color={getTextColor()} />}
      {<MenuItemLabel label={label} badgeCount={badgeCount} isSelected={isSelected} isCollapsed={collapsed}/>}
    </Flex>
  );
};

const createMenuItem = (
  label,
  key,
  Icon,
  children = null,
  badgeCount = null
) => ({
  key,
  children,
  title: label,
  label: (
    <MenuItemContent
      label={label}
      Icon={Icon}
      badgeCount={badgeCount}
      itemKey={key}
    />
  ),
});

export const navigationItems = [
  createMenuItem("Dashboard", "/home", DashboardDrawerIcon, null, 10),
];

export const mainItems = [
  createMenuItem("Reports", "/reports", ReportsDrawerIcon),
  createMenuItem("Ask your book", "/book", AskYourBookDrawerIcon),
  createMenuItem("Take a test", "/test", TakeATestDrawerIcon),
  // createMenuItem("Language Practice", "/lang-practice", LangPracticeDrawerIcon),
];

export const settingsItems = [
  createMenuItem("Settings", "/settings", SettingsDrawerIcon),
  // createMenuItem(
  //   "Notifications",
  //   "/notifications",
  //   NotificationsDrawerIcon,
  //   null,
  //   5
  // ),
];

export const logoutItem = [
  createMenuItem("Logout", "/logout", LogoutDrawerIcon),
];
